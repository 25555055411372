
import { defineComponent, ref, reactive, toRefs } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import http from '@/http'
import API from '@/http/api'
import { showLoading } from '@/utils/loading'
import HelpSearchBar from '@/components/HelpSearchBar.vue'

export default defineComponent({
  components: { HelpSearchBar },
  setup() {
    let markdownRef = ref()
    const updateLogList = reactive<any>([])
    const logProps = reactive({
      pages: 1,
      total: 0,
      curSelectId: '',
      mdEditorMsg: '',
      updateDate: '',
      keyword: '',
      platform: '',
      project: ''
    })

    // 按钮触发提交
    function btnMarkdownSave() {
      markdownRef.value.save()
    }
    // 提交
    function markdownSave(text: any, html: any) {
      if (logProps.curSelectId && logProps.curSelectId != 'new') {
        const data = {
          id: logProps.curSelectId,
          platform: logProps.platform,
          project: logProps.project,
          content: logProps.mdEditorMsg,
          webContent: html
        }
        showLoading('main')
        http({
          url: API.editHelpLog,
          method: 'post',
          data
        }).then((res: any) => {
          ElMessage({
            message: res.message,
            type: 'success'
          })
          newlyAdded('no')
          getUpdateLog()
        })
      } else {
        if (!logProps.updateDate) {
          ElMessage('请选择日期')
          return
        }
        const data = {
          platform: logProps.platform,
          project: logProps.project,
          date: getNowFormatDay(logProps.updateDate),
          content: logProps.mdEditorMsg,
          webContent: html
        }
        showLoading('main')
        http({
          url: API.insertHelpLog,
          method: 'post',
          data
        }).then((res: any) => {
          ElMessage({
            message: res.message,
            type: 'success'
          })
          newlyAdded('no')
          getUpdateLog()
        })
      }
    }
    // 上传图片
    function handleUploadImage(event: any, insertImage: any, files: any) {
      let filesdata = new FormData()
      filesdata.append('describe', 'other')
      filesdata.append('businessType', '1')
      filesdata.append('files', files[0])
      filesdata.append('type', '2')
      filesdata.append('isWaterMarker', '0')
      http({
        url: API.uploadUrl,
        method: 'post',
        data: filesdata
      }).then((res: any) => {
        if (res.result) {
          insertImage({
            url: res.result[0],
            desc: '图片地址'
          })
        }
      })
    }
    // 选中当前点击
    function clickItem(val: any) {
      console.log(val)
      logProps.mdEditorMsg = val.content
      logProps.updateDate = val.date
      logProps.curSelectId = val.id
    }
    // 删除当前选中
    function deleteItem() {
      if (!logProps.curSelectId || logProps.curSelectId == 'new') {
        ElMessage.warning('请选择日志')
        return
      }
      ElMessageBox.confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        showLoading('main')
        const data = { id: logProps.curSelectId }
        http({
          url: API.deleteHelpLog,
          method: 'post',
          data
        }).then((res: any) => {
          ElMessage({
            message: res.message,
            type: 'success'
          })
          newlyAdded('no')
          getUpdateLog()
        })
      })
    }
    // 是否选择所属端和项目
    function selectCondition(platform: string, project: string) {
      if (platform && project) {
        return true
      } else {
        if (platform) {
          ElMessage.warning('请选择所属项目')
        } else if (project) {
          ElMessage.warning('请选择所属端')
        } else {
          ElMessage.warning('请选择所属端和项目')
        }
        return false
      }
    }
    // 转换时间
    function getNowFormatDay(nowDate: any) {
      console.log(nowDate, 'nowDate')
      var char = '-'
      if (nowDate == null) {
        nowDate = new Date()
      }
      var day = nowDate.getDate()
      var month = nowDate.getMonth() + 1 //注意月份需要+1
      var year = nowDate.getFullYear()
      //补全0，并拼接
      return year + char + completeDate(month) + char + completeDate(day)
    }
    function completeDate(value: number) {
      return value < 10 ? '0' + value : value
    }
    function newlyAdded(val: any) {
      if (selectCondition(logProps.platform, logProps.project)) {
        logProps.mdEditorMsg = ''
        logProps.updateDate = ''
        logProps.curSelectId = ''
        if (val == 'new') {
          logProps.curSelectId = 'new'
        }
      }
    }
    function helpScreenQuery(screenValue: any) {
      logProps.platform = screenValue.platform
      logProps.project = screenValue.project
      getUpdateLog()
    }

    // 获取更新日志
    function getUpdateLog() {
      if (logProps.platform && logProps.project) {
        const data = {
          date: logProps.keyword,
          platform: logProps.platform,
          project: logProps.project,
          pageSize: 10,
          page: logProps.pages
        }
        showLoading('main')
        http({
          url: API.findHelpLog,
          method: 'post',
          data
        }).then(res => {
          updateLogList.length = 0
          const arr = Array.prototype.concat.call(res.data.records)
          updateLogList.push(...arr)
          logProps.total = res.data.total
        })
      } else {
        ElMessage.warning('请选择所属端和项目')
      }
    }

    return {
      updateLogList,
      markdownRef,
      ...toRefs(logProps),
      markdownSave,
      handleUploadImage,
      clickItem,
      deleteItem,
      getUpdateLog,
      newlyAdded,
      btnMarkdownSave,
      helpScreenQuery
    }
  }
})
