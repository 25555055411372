
import { defineComponent, reactive, toRefs, PropType, onMounted } from 'vue'
import { ElMessage } from 'element-plus'

const options = [
  {
    value: '1',
    label: 'WEB端',
    children: [
      {
        value: '1',
        label: '车载智能载重平台'
      },
      {
        value: '6',
        label: '流向管控平台'
      },
      {
        value: '8',
        label: '运维手册-群回复'
      }
    ]
  },
  {
    value: '2',
    label: 'APP端[Android]',
    children: [
      {
        value: '2',
        label: '汉德装车pro'
      },
      {
        value: '3',
        label: '汉德环卫'
      }
    ]
  },
  {
    value: '3',
    label: 'APP端[IOS]',
    children: [
      {
        value: '2',
        label: '汉德装车pro'
      },
      {
        value: '3',
        label: '汉德环卫'
      }
    ]
  },
  {
    value: '4',
    label: '小程序端',
    children: [
      {
        value: '4',
        label: '随你运'
      },
      {
        value: '5',
        label: '汉德车载平台'
      },
      {
        value: '7',
        label: '云天小程序'
      }
    ]
  }
]

/**
 * @description 帮助中心搜索栏可接受参数描述类型
 * @param platform string 平台: 1-web,2-Android,3-IOS,4-小程序
 * @param project string 项目: 1-车载智能称重平台,2-汉德装车pro,3-汉德环卫,4-随你运,5-汉德车载平台,6-流向管控平台,7-云天小程序
 * @param deep number //select组件 禁用状态指令: 0：全部开放（默认） 1：禁用平台选择、开放项目选择   2：全部禁用
 */
export interface HelpSearchProps {
  platform?: string
  project?: string
  deep?: string
}

export default defineComponent({
  name: 'HelpSearchBar',
  props: {
    data: {
      type: Object as PropType<HelpSearchProps>
    }
  },
  emits: ['helpScreenQuery'],
  setup(props, { emit }) {
    const selectProps = reactive({
      projectOption: [] as { value: string; label: string }[],
      platform: '',
      project: '',
      deep: ''
    })

    onMounted(() => {
      // console.log('onMounted == ', props.data)
      if (props.data) {
        const { platform, project, deep } = props.data
        selectProps.platform = platform || '1'
        selectProps.project = project || '1'
        selectProps.deep = deep || '0'
        if (selectProps.platform === '1') {
          selectProps.projectOption = options[0].children
        } else {
          selectProps.projectOption = options.filter(item => item.value === selectProps.platform)[0].children
        }
        const projectArray = selectProps.projectOption.filter(item => item.value === selectProps.project)
        if (projectArray.length === 0) {
          selectProps.project = ''
        }
      }
    })

    function helpScreenQuery() {
      if (selectProps.platform && selectProps.project) {
        emit('helpScreenQuery', selectProps)
      } else if (selectProps.platform || selectProps.project) {
        selectProps.platform ? ElMessage.warning('请选择所属项目') : ElMessage.warning('请选择所属端')
      } else {
        ElMessage.warning('请选择所属端和项目')
      }
    }

    function handlePlatform(val: string) {
      let valArr = options.filter(item => item.value === val)
      if (valArr.length && valArr[0].children) {
        selectProps.project = ''
        selectProps.projectOption = valArr[0].children
      }
    }

    return { helpScreenQuery, handlePlatform, ...toRefs(selectProps), options }
  }
})
