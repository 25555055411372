
import { defineComponent, ref, reactive, watch, nextTick, toRefs, toRef } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import http from '@/http'
import API from '@/http/api'
import { showLoading } from '@/utils/loading'
import HelpSearchBar from '@/components/HelpSearchBar.vue'

interface menuType {
  id: number
  cateName: string
  isEdit?: boolean
  module: string
  platform: string
  project: string
  cateId: string
  cateLevel: string
  cateType: string
  children: menuType[]
}

interface Node {
  id: number
  text: string
  checked: boolean
  indeterminate: boolean
  data: any
  expanded: boolean
  parent: Node
  visible: boolean
  childNodes: Node[]
}

type DropType = 'prev' | 'inner' | 'next'

// getMenuList 移动失败后重新请求数据
function useDrag<T extends (...args: any[]) => void>(getMenuList: T) {
  // 判断同级，不是同级不能 drop
  const allowDrop = (draggingNode: any, dropNode: any, type: DropType) => {
    return draggingNode.data.parentId === dropNode.data.parentId && type !== 'inner'
  }

  /**
   * 拖拽成功事件
   * @param _ 拖拽的节点
   * @param dropNode drop 位置的节点
   */
  const handleDrop = (_: Node, dropNode: Node) => {
    const { parent } = dropNode // 获取当前节点的 parent
    const { childNodes } = parent // 同层所有节点数据
    const currentLevelData = childNodes.map(node => node.data && node.data.id) // map 节点 id
    commitSort(currentLevelData.join(','))
  }

  async function commitSort(categoryIds: string) {
    try {
      await http({
        url: API.customCategorySort,
        method: 'post',
        data: {
          categoryIds
        }
      })
      // ElMessage.success('成功修改排序')
    } catch {
      console.log('error commit')
      getMenuList()
    }
  }

  return { allowDrop, handleDrop }
}

export default defineComponent({
  name: 'ProblemDocument',
  components: { HelpSearchBar },
  setup() {
    let markdownRef = ref()
    let menuDatas = reactive<menuType[]>([])
    let id = 1000
    const treeRef = ref()
    const inputRef = ref()
    const curData = ref()
    const curNode = ref()
    // 旧的当前选中数据
    const oldCurData = ref()
    let editStatus = false
    let editId = ref()
    // 是否是编辑目录名称
    let isModify = false
    const treeProps = reactive({
      filterText: '',
      expandAll: false,
      loading: true,
      helpTitle: '',
      mdEditorMsg: '',
      currentSelectId: 0,
      platform: '',
      project: '',
      treeEdit: false
    })

    // 获取当前点击的node
    function clickChildren(data: menuType, node: any) {
      curData.value = data
      curNode.value = node
      treeProps.helpTitle = ''
      treeProps.currentSelectId = data.id
    }
    // 当前选中编辑名称
    function currentSelect(data: menuType) {
      if (treeProps.treeEdit) {
        ElMessage.warning('当前存在编辑')
        return
      }
      treeProps.treeEdit = true
      oldCurData.value = JSON.parse(JSON.stringify(data))
      data.isEdit = true
      isModify = true
      nextTick(() => {
        inputRef.value.focus()
      })
    }
    // 双击选择编辑
    function dbclickItem(data: any) {
      curData.value = data
      editNode()
    }
    // 添加同级目录
    function addTree() {
      if (selectCondition(treeProps.platform, treeProps.project)) {
        if (treeProps.treeEdit) {
          ElMessage.warning('当前存在编辑')
          return
        }
        const newChild: menuType = {
          id: id++,
          cateName: '',
          isEdit: true,
          module: '2',
          platform: treeProps.platform,
          project: treeProps.project,
          cateId: '',
          cateLevel: '1',
          cateType: 'top',
          children: []
        }
        treeProps.treeEdit = true
        menuDatas.push(newChild)
        nextTick(() => {
          inputRef.value.focus()
        })
      }
    }
    // 添加子节点
    function append() {
      if (selectCondition(treeProps.platform, treeProps.project)) {
        if (curData.value) {
          if (treeProps.treeEdit) {
            ElMessage.warning('当前存在编辑')
            return
          }
          const newChild: menuType = {
            id: id++,
            cateName: '',
            isEdit: true,
            module: '2',
            platform: treeProps.platform,
            project: treeProps.project,
            cateId: curData.value.id + '',
            cateLevel: curData.value.cateLevel + '',
            cateType: 'son',
            children: []
          }
          curData.value.children.push(newChild)
          curNode.value.expanded = true
          curData.value = undefined
          treeProps.treeEdit = true
          nextTick(() => {
            setTimeout(() => {
              inputRef.value.focus()
            }, 300)
          })
        } else {
          treeProps.treeEdit = false
          ElMessage.warning('请选择目录')
        }
      }
    }
    // 删除节点
    function remove() {
      // 添加弹窗确认删除操作
      if (curData.value) {
        const data = {
          parentId: curData.value.parentId,
          cateId: curData.value?.id,
          module: '1',
          platform: treeProps.platform,
          project: treeProps.project
        }
        ElMessageBox.confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          showLoading('main')
          http({
            url: API.delCategoryNode,
            method: 'post',
            data
          }).then((res: any) => {
            ElMessage({
              message: res.message,
              type: 'success'
            })
            const parent = curNode.value.parent
            const children = parent.data.children || parent.data
            const index = children.findIndex((d: any) => d.id === curData.value?.id)
            children.splice(index, 1)
            curNode.value = undefined
            curData.value = undefined
          })
        })
      } else {
        ElMessage.warning('请选择目录')
      }
    }
    // 编辑
    function editNode() {
      if (curData.value) {
        treeProps.loading = true
        const data = { cateId: curData.value.id }
        showLoading('main')
        http({
          url: API.findContentByCateId,
          method: 'post',
          data
        }).then(res => {
          treeProps.mdEditorMsg = ''
          treeProps.helpTitle = curData.value.cateName
          if (res.data.length) {
            editStatus = true
            editId.value = res.data[0].id
            treeProps.mdEditorMsg = res.data[0].content
          } else {
            editStatus = false
            editId.value = ''
          }
          treeProps.loading = false
        })
      } else {
        ElMessage.warning('请选择目录')
      }
    }

    // 全部展开收起
    function openTree() {
      treeProps.expandAll = !treeProps.expandAll
      eachOpenTree(menuDatas)
    }
    function eachOpenTree(data: menuType[]) {
      data.forEach((ele: any) => {
        treeRef.value.store.nodesMap[ele.id].expanded = treeProps.expandAll
        ele.children && ele.children.length > 0 ? eachOpenTree(ele.children) : ''
      })
    }
    // 取消添加
    function cancelAdd(data: any, node: any) {
      treeProps.treeEdit = false
      if (isModify) {
        data.cateName = oldCurData.value.cateName
        data.isEdit = false
        isModify = false
      } else {
        const parent = node.parent
        const children = parent.data.children || parent.data
        const index = children.findIndex((d: any) => d.id === data.id)
        children.splice(index, 1)
      }
    }
    // 确认添加
    function confirmAdd(dataval: any) {
      // 限制空格提交
      let replaceName = dataval.cateName.replace(/^\s+|\s+$/g, '')
      if (!replaceName) {
        ElMessage.warning('请填写标题')
        return
      }
      showLoading('main')
      treeProps.treeEdit = false
      if (isModify) {
        const data = { cateId: dataval.id, cateName: dataval.cateName }
        http({
          url: API.modifyCategoryName,
          method: 'post',
          data
        }).then((res: any) => {
          ElMessage({
            message: res.message,
            type: 'success'
          })
          isModify = false
          dataval.isEdit = false
        })
      } else {
        dataval.isEdit = false
        const data = dataval
        http({
          url: API.addCategoryNode,
          method: 'post',
          data
        }).then((res: any) => {
          ElMessage({
            message: res.message,
            type: 'success'
          })
          dataval.id = res.data.cateId
          dataval.parentId = dataval.cateId
          dataval.cateLevel = res.data.cateLevel
        })
      }
    }
    // 筛选过滤
    watch(toRef(treeProps, 'filterText'), (newProps, oldProps) => {
      console.log(oldProps)
      treeRef.value.filter(newProps)
    })
    function filterNode(value: any, data: any) {
      console.log(value)
      if (!value) return true
      return data.cateName.indexOf(value) !== -1
    }

    function btnMarkdownSave() {
      markdownRef.value.save()
    }
    // 是否选择所属端和项目
    function selectCondition(platform: string, project: string) {
      if (platform && project) {
        return true
      } else {
        if (platform) {
          ElMessage.warning('请选择所属项目')
        } else if (project) {
          ElMessage.warning('请选择所属端')
        } else {
          ElMessage.warning('请选择所属端和项目')
        }
        return false
      }
    }
    // 提交
    function markdownSave(text: any, html: any) {
      if (curData.value) {
        curData.value.cateName = treeProps.helpTitle
        console.log(text, html)
        let data: any = {
          content: treeProps.mdEditorMsg,
          webContent: html
        }
        if (!editStatus) {
          data.title = treeProps.helpTitle
          data.cateId = curData.value.id
        } else {
          data.contentId = editId.value
        }
        showLoading('main')
        http({
          url: editStatus ? API.modifyContentByContentId : API.addContent,
          method: 'post',
          data
        }).then((res: any) => {
          ElMessage({
            message: res.message,
            type: 'success'
          })
          treeProps.helpTitle = ''
        })
      } else {
        ElMessage.warning('请选择目录')
      }
    }
    //上传图片
    function handleUploadImage(event: any, insertImage: any, files: any) {
      console.log(event)
      let filesdata = new FormData()
      filesdata.append('describe', 'other')
      filesdata.append('businessType', '1')
      filesdata.append('files', files[0])
      filesdata.append('type', '2')
      filesdata.append('isWaterMarker', '0')
      http({
        url: API.uploadUrl,
        method: 'post',
        data: filesdata
      }).then((res: any) => {
        if (res.result) {
          insertImage({
            url: res.result[0],
            desc: '图片地址'
          })
        }
      })
    }

    const searchOptions = ref()

    // 获取菜单
    function getMenuList(screenValue: any) {
      // 保存选项，排序失败后需要重新请求接口
      if (!screenValue) {
        screenValue = searchOptions.value
      } else {
        searchOptions.value = screenValue
      }
      treeProps.platform = screenValue.platform
      treeProps.project = screenValue.project
      treeProps.expandAll = false
      treeProps.treeEdit = false
      const data = { module: '2', platform: treeProps.platform, project: treeProps.project, isMobile: '0' }
      showLoading('main')
      http({
        url: API.findCategoryListByTree,
        method: 'post',
        data
      }).then(res => {
        menuDatas.length = 0
        const arr = Array.prototype.concat.call(res.data)
        menuDatas.push(...arr)
      })
    }

    return {
      menuDatas,
      treeRef,
      inputRef,
      markdownRef,
      append,
      remove,
      editNode,
      addTree,
      clickChildren,
      openTree,
      cancelAdd,
      confirmAdd,
      filterNode,
      markdownSave,
      handleUploadImage,
      currentSelect,
      btnMarkdownSave,
      getMenuList,
      dbclickItem,
      ...toRefs(treeProps),
      ...useDrag(getMenuList)
    }
  }
})
